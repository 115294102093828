import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

const SecondPage = () => (
  <Layout>
    <SEO
      title="Colorado Custom & Luxury Residential Design "
      description="JKA Design is a full-service architectural firm that provides custom living space and luxury residential design and construction services in Colorado."
    />
    <div class="page-headline">
      <div class="container">
        <div class="section-heading text-center">
          <h6 class="font-weight-bold text-uppercase text-white-50 flair">
            Services
          </h6>
          <h1>
            <strong>Custom & Luxury Residential Design in Colorado</strong>
          </h1>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-12 mb-4">
            <h4>A few of our custom design projects</h4>
            <div class="row justify-content-around py-5">
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/portfolio-item/custom-euro-style-home">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/083d099318bfd68922abd10b296f370038016d4e-850x638.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Custom Euro-Style Home</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/portfolio-item/tuscan-estate">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/11b282a7f4ec1101616d39ebb7480f1d548f7581-800x600.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Tuscan Estate</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/portfolio-item/whole-house-remodel">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/6a4c2b77bfa43c9c71bbed8e595e8c33e34a1dc0-300x225.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Whole House Remodel</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <h4>
              Jeffry K. Abrams, Architect is a full-service architectural firm
              offering the following custom and luxury residential design
              services:
            </h4>
            <div class="row justify-content-around py-5">
              <div class="col-md-6">
                <ul className="services-list">
                  <li>Planning &amp; Zoning Consultation and Advising</li>
                  <li>Code research</li>
                  <li>Site Planning</li>
                  <li>Architectural Design</li>
                  <li>Preliminary Design</li>
                  <li>Schematic Design</li>
                  <li>Custom and Luxury Residential Design</li>
                  <li>Construction Documents</li>
                  <li>Working Drawings</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul className="services-list">
                  <li>Construction Detailing</li>
                  <li>As Built Measuring and Drawings</li>
                  <li>Energy Consulting</li>
                  <li>Passive Solar Consultation and Design</li>
                  <li>Bidding or Negotiation</li>
                  <li>Construction Contract Consultancy</li>
                  <li>Site Observation and Advising</li>
                  <li>Design Review Consultation</li>
                  <li>Forensics</li>
                </ul>
              </div>
            </div>
            <div class="row justify-content-around py-5">
              <div class="col-md-6 mb-4">
                <h3 className="mb-4">
                  Why It Looks Like That: The Design Process
                </h3>
                <iframe
                  src="https://www.youtube.com/embed/vmHoGicPQQQ?rel=0&amp;showinfo=0&amp;wmode=opaque"
                  width="100%"
                  height="315"
                  frameborder="0"
                  title="Why It Looks Like That: The Design Process"
                  allowfullscreen="allowfullscreen"
                ></iframe>
              </div>
              <div class="col-md-6 mb-4">
                <h3 className="mb-4">What Should Your House Look Like?</h3>
                <iframe
                  src="https://www.youtube.com/embed/zAhgz7LIau4?rel=0&amp;showinfo=0&amp;wmode=opaque"
                  width="100%"
                  height="315"
                  frameborder="0"
                  title="What Should Your House Look Like?"
                  allowfullscreen="allowfullscreen"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="review-snippet padding-50 text-center text-white">
      <div className="container">
        <p className="text-white">
          <i className="fa fa-quote-left mr-3" />
          <em>
            “We live in a home designed by Jeff Abrams and for more than 20
            years have been delighted by his work. Our home is both beautiful
            and functional as a result of Jeff’s thoughtful creativity. Jeff has
            also advised us over the years on potential remodel and improvement
            projects. We value and appreciate his insights and suggestions. We
            highly recommend Jeff Abrams as a home design and remodel
            professional.”
          </em>
          <i className="fa fa-quote-right ml-3" />
        </p>
      </div>
      <em>–Gordon R., Client</em>
    </div>
  </Layout>
)

export default SecondPage
